import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mashing"
    }}>{`Mashing`}</h1>
    <p>{`768 + 725 + 842 + 790 + 816 + 896 + 563 = 5400 g pale ale malt`}</p>
    <p>{`810 + 390`}</p>
    <p>{`500 g biscuit malt`}</p>
    <p>{`250 g china pilsner malt`}</p>
    <h2 {...{
      "id": "process"
    }}>{`Process`}</h2>
    <ul>
      <li parentName="ul">{`Boil the water(higher temperature) to 72 `}{`°`}{`C`}</li>
      <li parentName="ul">{`add the malt into water and keep 65`}{`°`}{`C`}</li>
      <li parentName="ul">{`add all the malt inside and stir genteel`}</li>
      <li parentName="ul">{`(optional) 15 minutes cook with 55`}{`°`}{`C, and remove the protein layer`}</li>
      <li parentName="ul">{`10 minutes before the mashing ending, heating the sparging water to 76`}{`°`}{`C`}</li>
      <li parentName="ul">{`after 50 minutes heating, now adding the sparging water into the pot`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      